const ServiceData = [
  {
    title: "INTERNATIONAL SHIPPING SERVICE",
    description:
      "We can effortlessly deliver your bulky shipments overseas at rates that are comparable with the market. By deciding on us, we guarantee that your experience will be seamless and extraordinary and that you will have total peace of mind when it comes to transporting goods over the world. Additionally, we provide the lowest prices in Pakistan for import and export to other countries.",
    img: "/service/internation service.png",
  },

  {
    title: "Overnight Services",
    description:
      "We consistently provide on-time delivery for important business. We pride ourselves on providing professionalism and first-rate service. Delivery is made overnight to every destination through our vast network.",
    img: "/service/SAME DAY DELIVERY.png",
  },
  {
    title: "Sustainability",
    description:
      "Utilize our services and procedures, which were developed with our commitment to effectiveness and environmental responsibility in mind, to help you achieve your sustainability goals. We Utilize our technology to integrate into your systems or website to improve workflow and share throughout your company.",
    img: "/service/custom clearance.png",
  },
 

  {
    title: "SEA FREIGHT",
    description:
      "Although there are several cargo businesses now functioning in the nation, we offer the quickest, most dependable, and most reasonably priced services. We efficiently and promptly transport your freight packages to any of the locations you choose. Never before have domestic freight services in Pakistan been this seamless, hassle-free, and dependable.",
    img: "/service/SEA FREIGHT.png",
  },
  
  {
    title: "CASH ON DELIVERY",
    description:
      "With our Cash on Delivery services, you can take your business nationwide. We offer services to lower the risk of fraud and default. With our 24-hour customer support and shipment tracking service, keep track of your packages. Use our assistance to boost your sales, earnings, and customer base.",
    img: "/service/CASH ON DELIVERY.png",
  },
];

export default ServiceData;
